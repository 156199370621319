<template>
  <div :class="$style.image">
    <div id="left" :class="$style.left">
      <div :class="$style.header">
        <div :class="$style.title">
          <BjInput v-model="params.title" placeholder="请在这里输入标题" :max-length="50" />
          <span :class="$style.words">{{ params.title ? params.title.length : 0 }} / 50</span>
        </div>
        <div :class="$style.line" />
        <div :class="$style.imgBox">
          <div v-for="(item, index) in params.image" :key="index" :class="$style.imgPad">
            <div :class="$style.imgItem">
              <div :class="$style.boxLeft">
                <i v-if="!item.cover" class="ri-add-line" @click="onChoose(index)" />
                <img :class="$style.img" :src="item.cover" />
                <div v-if="item.cover" :class="$style.deletebtn">
                  <i class="ri-eye-line" @click="onPreview(item)" />
                  <i class="ri-delete-bin-7-line" @click="onDeleteImg(index)" />
                </div>
              </div>
              <div :class="$style.boxRight">
                <a-textarea
                  v-model="item.intro"
                  :class="$style.textarea"
                  :auto-size="{ minRows: 3, maxRows: 3 }"
                  placeholder="填写图片的描述信息。"
                />
                <div :class="$style.boxRightDelete">
                  <i class="ri-delete-bin-7-line" @click="onDelete(index)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.btns">
          <div :class="$style.btn" @click="onAdd()">
            <i class="ri-add-line" />
            添加一行
          </div>
        </div>
      </div>
      <Detail ref="detail" :type="params.content_type" :data="params" />
      <div :class="$style.footer" :style="{ width: footWidth }">
        <div :class="$style.num">图片数量：{{ num }} 个</div>
        <div :class="$style.btn">
          <BjButton type="primary" @click="onSave()">
            <i class="ri-save-line left" />
            立即保存
          </BjButton>
          <BjButton @click="onSave(true)"> 保存并上架 </BjButton>
          <BjButton :class="$style.ml20" @click="onSavePreview()"> 预览 </BjButton>
        </div>
      </div>
    </div>
    <chooseMaterial :visible.sync="visible.choose" active="image" :allow="['image']" @getChoose="getChoose" />
  </div>
</template>

<script>
import _ from 'lodash'

import chooseMaterial from '@/components/chooseMaterial'
import { manageService } from '@/service'

import Detail from './Detail.vue'

const service = new manageService()

export default {
  name: 'AddAtlas',
  components: {
    Detail,
    chooseMaterial,
  },
  data() {
    return {
      params: {
        content_type: 'image',
        title: null,
        content: null,
        introduce: null,
        image: [],
      },
      visible: {
        choose: false,
      },
      imgIndex: 0,
      footWidth: '800px',
    }
  },
  computed: {
    num() {
      return this.params.image.length || 0
    },
  },
  created() {
    if (this.$route.params.id !== 'add') {
      this.getInfo()
    }
  },
  mounted() {
    this.footWidth = document.getElementById('left').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('left').offsetWidth + 'px'
    }
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.info({
          id: this.$route.params.id,
        })
        this.params = data
      } catch (e) {}
    },
    async onSave(type) {
      let param = _.cloneDeep(this.$refs.detail.params)
      let relation_poc = []
      this.$refs.detail.pocData.map(item => {
        relation_poc.push(item.id)
      })
      param.type_id = param.type_id ? param.type_id[param.type_id.length - 1] : null
      param.is_original_declare = param.is_original_declare ? 1 : 0
      param.group_id = Array.isArray(param.group_id) ? '' : param.group_id
      try {
        await service.save({
          info: JSON.stringify({
            ...this.params,
            ...param,
            relation_poc: relation_poc,
          }),
          id: this.$route.params.id !== 'add' ? this.$route.params.id : '',
          is_online: type ? 1 : 0, // 是否上架
        })
        this.$message.success('添加成功')
        this.$router.push({
          name: 'contentManage',
        })
      } catch (e) {}
    },
    getChoose(data) {
      const tmp = {
        id: data[0].id,
        cover: data[0].cover,
        intro: this.params.image[this.imgIndex].intro,
        type: 'image',
      }
      this.params.image.splice(this.imgIndex, 1, tmp)
    },
    onAdd() {
      this.params.image.push({
        type: 'image',
        intro: null,
        id: null,
      })
    },
    onChoose(index) {
      this.imgIndex = index
      this.visible.choose = true
    },
    onDeleteImg(index) {
      this.params.image[index].cover = null
      this.params.image[index].id = null
    },
    onDelete(index) {
      this.params.image.splice(index, 1)
    },
    onPreview(item) {
      this.BjPreview(this.params.image, item.cover)
    },
    async onSavePreview() {
      let param = _.cloneDeep(this.$refs.detail.params)
      let relation_poc = []
      this.$refs.detail.pocData.map(item => {
        relation_poc.push(item.id)
      })
      param.type_id = param.type_id ? param.type_id[param.type_id.length - 1] : null
      param.is_original_declare = param.is_original_declare ? 1 : 0
      param.group_id = Array.isArray(param.group_id) ? '' : param.group_id
      try {
        const res = await service.save({
          info: JSON.stringify({
            ...this.params,
            ...param,
            relation_poc: relation_poc,
          }),
          id: this.$route.params.id !== 'add' ? this.$route.params.id : '',
          is_online: 0,
        })
        if (this.$route.params.id === 'add') {
          this.$router.push({
            name: 'contentManageAddAtlas',
            params: {
              id: res.data.id,
            },
          })
        }
        this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
        this.$router.push({
          name: 'frontPreview',
          query: {
            type: 'content',
            id: res.data.id,
          },
        })
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.image {
  .header {
    padding: 20px;
    background: #fff;

    .title {
      position: relative;

      .words {
        position: absolute;
        top: 7px;
        right: 5px;
      }

      :global {
        .ant-input {
          padding-right: 70px;
          padding-left: 0;
          font-size: 20px;
          border: none;
        }

        .ant-input:focus {
          border: none;
          box-shadow: none;
        }
      }

      ::-webkit-input-placeholder {
        color: #999;
        font-size: 20px;
      }
    }

    .line {
      width: 100%;
      border-top: 1px solid #eee;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    height: 60px;
    padding: 0 20px;
    line-height: 60px;
    background: #fff;

    .num {
      width: 200px;
    }

    .btn {
      flex: 1;
      text-align: right;
      /* stylelint-disable-next-line no-descending-specificity */
      :global {
        .ant-btn {
          width: 100px;
          height: 36px;
          margin-left: 10px;
          padding: 0;
        }
      }
    }

    .ml20 {
      margin-left: 20px !important;
    }
  }
}

.img-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .img-pad {
    width: 33.3%;
    height: 80px;
    margin-top: 20px;
  }

  .img-pad:nth-child(3n + 1) {
    padding-right: 10px;
  }
  .img-pad:nth-child(3n + 2) {
    padding: 0 10px;
  }
  .img-pad:nth-child(3n + 0) {
    padding-left: 10px;
  }

  .img-item {
    display: flex;
    height: 80px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

    .box-left {
      position: relative;
      width: 80px;
      line-height: 80px;
      text-align: center;
      border-right: 1px solid #eee;

      .img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }

      i {
        color: #5c5c5c;
        font-size: 30px;
      }

      .deletebtn {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 18px;
        line-height: 80px;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);

        i {
          color: #fff;
          font-size: 18px;
        }

        i:nth-child(2) {
          margin-left: 11px;
        }
      }

      &:hover .deletebtn {
        display: block;
      }
    }

    .box-right {
      position: relative;
      flex: 1;
      /* stylelint-disable-next-line no-descending-specificity */
      :global {
        /* stylelint-disable-next-line no-descending-specificity */
        .ant-input {
          height: 100%;
          border: none;
        }

        .ant-input:focus {
          border: none;
          box-shadow: none;
        }
      }

      &-delete {
        position: absolute;
        top: 8px;
        right: 8px;
        display: none;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background: #fff;
        border: 1px solid #eee;
        /* stylelint-disable-next-line no-descending-specificity */
        i {
          font-size: 14px;
        }
      }
    }

    &:hover .box-right-delete {
      display: block;
    }
  }
}

.btns {
  width: 100%;
  margin-top: 20px;
  text-align: right;
  /* stylelint-disable-next-line no-descending-specificity */
  .btn {
    display: inline-block;
    width: 90px;
    height: 40px;
    color: #fff;
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    background: @primary-color;
    border-radius: 100px 0 0 100px;
    cursor: pointer;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      color: #fff;
      font-size: 18px;
      vertical-align: -4px;
    }
  }
}

.stitle {
  color: #000;
  font-size: 14px;
}

.textarea {
  height: 78px !important;
  max-height: 78px !important;
}
</style>
